import React, { useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../components/Archive/stylesParams';
import {
  useSelector,
  useActions,
} from '../../../common/components/runtime-context';
import { getArchiveWidgetData } from '../../aggregated-archive/selectors';
import LinkList from '../link-list-new';
import LinkText from '../link-text';

const Archive = () => {
  const archiveData = useSelector(getArchiveWidgetData);
  const actions = useActions();
  const { isEditor } = useEnvironment();

  const styles = useStyles();

  const showNewestPostsFirst = styles.get(stylesParams.showNewestPostsFirstV2);
  const monthsDisplayLimit = styles.get(stylesParams.monthsDisplayLimitV2);
  const [showNewestPostsFirstValue, setShowNewestPostFirstValue] =
    React.useState(showNewestPostsFirst);
  const [monthsDisplayLimitValue, setMonthsDisplayLimitValue] =
    React.useState(monthsDisplayLimit);

  useEffect(() => {
    if (!isEditor) {
      return;
    }

    const showNewestPostsFirstChange =
      showNewestPostsFirstValue !== showNewestPostsFirst;
    const monthsDisplayLimitChange =
      monthsDisplayLimitValue !== monthsDisplayLimit;

    if (monthsDisplayLimitChange || showNewestPostsFirstChange) {
      const fetchArchiveData = async () => {
        await actions.fetchArchiveWidgetInitialData({
          showNewestPostsFirst,
          monthsDisplayLimit,
        });
      };

      fetchArchiveData().finally(() => {
        setShowNewestPostFirstValue(showNewestPostsFirst);
        setMonthsDisplayLimitValue(monthsDisplayLimit);
      });
    }
  }, [
    showNewestPostsFirstValue,
    showNewestPostsFirst,
    actions,
    isEditor,
    monthsDisplayLimit,
    monthsDisplayLimitValue,
  ]);

  const links = React.useMemo(() => {
    const { archive } = archiveData;

    return archive.slice(0, monthsDisplayLimit).map((archiveLink) => ({
      key: archiveLink.id,
      path: archiveLink.path,
      text: (
        <LinkText
          postCount={archiveLink.display.postCount}
          a11yText={archiveLink.display.a11yText}
        >
          {archiveLink.display.text}
        </LinkText>
      ),
    }));
  }, [archiveData, monthsDisplayLimit]);

  return (
    <nav aria-label={archiveData.ariaLabel}>
      <LinkList links={links} emptyState={{ text: archiveData.emptyState }} />
    </nav>
  );
};

export default Archive;
